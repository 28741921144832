import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as PortfolioActions from './portfolio.actions';
import { Portfolio } from '../../models';
type Nullable<T> = T | null;
export interface PortfolioState extends EntityState<Portfolio> {
  selectedPortfolio: Nullable<Portfolio>;
  selectedPortfolioId: number | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const portfolioAdapter: EntityAdapter<Portfolio> =
  createEntityAdapter<Portfolio>({
    selectId: (portfolio: Portfolio) => portfolio.id,
    sortComparer: false,
  });

export const initialState: PortfolioState = portfolioAdapter.getInitialState({
  selectedPortfolioId: null,
  selectedPortfolio: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  loading: false,
});

export const portfolioReducer = createReducer(
  initialState,
  on(PortfolioActions.loadPortfolio, state => {
    return { ...state, loading: true };
  }),
  on(
    PortfolioActions.loadPortfolioSuccess,
    (state, { portfolio, currentPage, totalPages, totalItems }) =>
      portfolioAdapter.setAll([...portfolio], {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      })
  ),
  on(PortfolioActions.selectPortfolio, (state, { id }) => {
    return { ...state, selectedPortfolioId: id };
  }),
  on(PortfolioActions.loadPortfolioByCompanyId, state => {
    return { ...state, selectedPortfolio: null, loading: true };
  }),
  on(
    PortfolioActions.loadPortfolioByCompanyIdSuccess,
    (state, { portfolio }) => {
      return {
        ...state,
        selectedPortfolio: portfolio,
        loading: false,
        error: null,
      };
    }
  ),
  on(PortfolioActions.loadPortfolioByCompanyIdFailure, (state, { error }) => {
    return {
      ...state,
      selectedPortfolio: null,
      loading: false,
      error,
    };
  }),
  on(PortfolioActions.addCompanyToPortfolio, state => {
    return { ...state, loading: true };
  }),
  on(PortfolioActions.addCompanyToPortfolioSuccess, (state, { portfolio }) => {
    return {
      ...state,
      selectedPortfolio: portfolio,
      loading: false,
      error: null,
    };
  }),
  on(PortfolioActions.addCompanyToPortfolioFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  on(PortfolioActions.deletePortfolio, state => {
    return { ...state, loading: true };
  }),
  on(PortfolioActions.deletePortfolioSuccess, state => {
    return {
      ...state,
      selectedPortfolio: null,
      loading: false,
      error: null,
    };
  }),
  on(PortfolioActions.deletePortfolioFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  })
);

export function reducer(state: PortfolioState | undefined, action: Action) {
  return portfolioReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  portfolioAdapter.getSelectors();

// select the array of user ids
export const selectPortfolioIds = selectIds;

// select the dictionary of user entities
export const selectPortfolioEntities = selectEntities;

// select the array of users
export const selectAllPortfolio = selectAll;

// select the total user count
export const selectPortfolioTotal = selectTotal;
