import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as DirectorshipActions from './directorship.actions';
import { CompanyDirectorship } from '../../models';

export interface DirectorshipState extends EntityState<CompanyDirectorship> {
  selectedDirectorshipId: number | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const directorshipAdapter: EntityAdapter<CompanyDirectorship> =
  createEntityAdapter<CompanyDirectorship>({
    selectId: (directorship: CompanyDirectorship) => directorship.id,
    sortComparer: false,
  });

export const initialState: DirectorshipState =
  directorshipAdapter.getInitialState({
    selectedDirectorshipId: null,
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    loading: false,
  });

export const directorshipReducer = createReducer(
  initialState,
  on(DirectorshipActions.loadDirectorships, state => {
    return { ...state, loading: true };
  }),
  on(
    DirectorshipActions.loadDirectorshipsSuccess,
    (state, { directorships, currentPage, totalPages, totalItems }) => {
      const entities =
        currentPage === 1
          ? [...directorships]
          : [...selectAllDirectorships(state), ...directorships];
      return directorshipAdapter.setAll(entities, {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      });
    }
  )
);

export function reducer(state: DirectorshipState | undefined, action: Action) {
  return directorshipReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  directorshipAdapter.getSelectors();

// select the array of user ids
export const selectDirectorshipIds = selectIds;

// select the dictionary of user entities
export const selectDirectorshipEntities = selectEntities;

// select the array of users
export const selectAllDirectorships = selectAll;

// select the total user count
export const selectDirectorshipTotal = selectTotal;
