import { Routes } from '@angular/router';
import { environment } from '../../environments/environment';
const appThemeName: string = environment.appThemeName;
const Routing: Routes = [
  {
    path: '',
    title: `${appThemeName} - Home`,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'directors',
    title: `${appThemeName} - Directors`,
    loadChildren: () =>
      import('./directors/directors.module').then(m => m.DirectorsModule),
  },
  {
    path: 'rankings',
    title: `${appThemeName} - Rankings`,
    loadChildren: () =>
      import('./rankings/rankings.module').then(m => m.RankingsModule),
  },
  {
    path: 'boards',
    title: `${appThemeName} - Boards`,
    loadChildren: () =>
      import('./boards/boards.module').then(m => m.BoardsModule),
  },
  {
    path: 'portfolio',
    title: `${appThemeName} - Portfolio`,
    loadChildren: () =>
      import('./portfolio/portfolio.module').then(m => {
        return m.PortfolioModule;
      }),
  },
  {
    path: 'watchlist',
    title: `${appThemeName} - Watchlist`,
    loadChildren: () =>
      import('./watchlist/watchlist.module').then(m => {
        return m.WatchlistModule;
      }),
  },
  {
    path: 'company/:id/:title',
    loadChildren: () =>
      import('./company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'article/:id/:title',
    loadChildren: () =>
      import('./article-post/article-post.module').then(
        m => m.ArticlePostModule
      ),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
