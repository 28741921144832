import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as WatchlistActions from './watchlist.actions';
import { Watchlist } from '../../models';
type Nullable<T> = T | null;
export interface WatchlistState extends EntityState<Watchlist> {
  selectedWatchlist: Nullable<Watchlist>;
  selectedWatchlistId: number | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const watchlistAdapter: EntityAdapter<Watchlist> =
  createEntityAdapter<Watchlist>({
    selectId: (watchlist: Watchlist) => watchlist.id,
    sortComparer: false,
  });

export const initialState: WatchlistState = watchlistAdapter.getInitialState({
  selectedWatchlistId: null,
  selectedWatchlist: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  loading: false,
});

export const watchlistReducer = createReducer(
  initialState,
  on(WatchlistActions.loadWatchlist, state => {
    return { ...state, loading: true };
  }),
  on(
    WatchlistActions.loadWatchlistSuccess,
    (state, { watchlist, currentPage, totalPages, totalItems }) =>
      watchlistAdapter.setAll(watchlist, {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      })
  ),
  on(WatchlistActions.selectWatchlist, (state, { id }) => {
    return { ...state, selectedWatchlistId: id };
  }),
  on(WatchlistActions.loadWatchlistByCompanyId, state => {
    return { ...state, selectedWatchlist: null, loading: true };
  }),
  on(
    WatchlistActions.loadWatchlistByCompanyIdSuccess,
    (state, { watchlist }) => {
      return {
        ...state,
        selectedWatchlist: watchlist,
        loading: false,
        error: null,
      };
    }
  ),
  on(WatchlistActions.loadWatchlistByCompanyIdFailure, (state, { error }) => {
    return {
      ...state,
      selectedWatchlist: null,
      loading: false,
      error,
    };
  }),
  on(WatchlistActions.addCompanyToWatchlist, state => {
    return { ...state, loading: true };
  }),
  on(WatchlistActions.addCompanyToWatchlistSuccess, (state, { watchlist }) => {
    return {
      ...state,
      selectedWatchlist: watchlist,
      loading: false,
      error: null,
    };
  }),
  on(WatchlistActions.addCompanyToWatchlistFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  on(WatchlistActions.deleteWatchlist, state => {
    return { ...state, loading: true };
  }),
  on(WatchlistActions.deleteWatchlistSuccess, state => {
    return {
      ...state,
      selectedWatchlist: null,
      loading: false,
      error: null,
    };
  }),
  on(WatchlistActions.deleteWatchlistFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  })
);

export function reducer(state: WatchlistState | undefined, action: Action) {
  return watchlistReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  watchlistAdapter.getSelectors();

// select the array of user ids
export const selectWatchlistIds = selectIds;

// select the dictionary of user entities
export const selectWatchlistEntities = selectEntities;

// select the array of users
export const selectAllWatchlist = selectAll;

// select the total user count
export const selectWatchlistTotal = selectTotal;
