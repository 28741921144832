import { createAction, props } from '@ngrx/store';
import { Podcast } from '../../models';
export const loadPodcasts = createAction(
  '[Podcast] Load Podcasts',
  props<{ page?: number; limit?: number }>()
);

export const loadPodcastsSuccess = createAction(
  '[Podcast] Load Podcasts Success',
  props<{
    podcasts: Podcast[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadPodcastsFailure = createAction(
  '[Podcast] Load Podcasts Failure',
  props<{
    error: any;
  }>()
);

export const selectPodcast = createAction(
  '[Podcast] Select Podcast',
  props<{ id: number }>()
);
