<nav
  id="navbar_top"
  class="navbar navbar-expand-lg navbar-light shadow-sm sticky-top mb-4">
  <!-- Container wrapper -->
  <div class="container-fluid-boxed">
    <!-- Navbar brand -->
    <a class="navbar-brand" href="#" routerLink="/">
      <img src="./assets/media/logos/free_float_platform_logo.png" alt="FF Logo" height="45" />
    </a>

    <!-- Toggle button -->
    <button
      (click)="toggleNavbarCollapsing()"
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      Menu
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collapsible wrapper -->
    <div [class.collapse]="navbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="(user$ | async) as user else publicMenu">
      <!-- Left links -->
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0 pb-md-3 pt-md-3">
        <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
          <a class="nav-link" aria-current="page" href="#" routerLink="/rankings">Rankings</a>
        </li>
        <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
          <a
            class="nav-link"
            aria-current="page"
            href="#"
            routerLink="/directors"
          >Directors</a
          >
        </li>
        <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
          <a class="nav-link" aria-current="page" href="#" routerLink="/boards"
          >Boards</a
          >
        </li>
        <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
          <a
            class="nav-link"
            aria-current="page"
            href="#"
            routerLink="/portfolio"
          >Portfolio</a
          >
        </li>
        <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
          <a
            class="nav-link"
            aria-current="page"
            href="#"
            routerLink="/watchlist"
          >Watchlists</a>
        </li>

      </ul>
      <div ngbDropdown class="dropdown text-center">
        <a [routerLink]="" ngbDropdownToggle class="d-block link-dark text-decoration-none dropdown-toggle" role="button" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="m-0 me-3 h5">{{user.email}}</span>
          <img src="./assets/media/static/george_rainbow_mohawk_min.webp" class="rounded-circle avatar-img shadow-sm"
               alt="Avatar" />
        </a>
        <ul ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownUser1">
          <li *ngIf="isPremiumPlansEnabled"><a class="dropdown-item" routerLink="/subscribe"><i class="fa fa-star pe-2"></i>Buy a Premium Plan!</a></li>
          <li><a class="dropdown-item" href="#" (click)="openHelpPage($event)"><i class="bi bi-question-circle pe-2"></i>Methodology Documents</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#" (click)="logOut($event)"><i class="bi bi-box-arrow-right pe-2"></i>Sign out</a></li>
        </ul>
      </div>
      <!-- Left links -->
<!--      <div class="d-flex align-items-center">-->
<!--        <button class="btn rounded-0 me-1" type="button" (click)="openHelpPage()" [matTooltip]="'Methodology Documents'" matTooltipPosition="below">-->
<!--          <i class="bi bi-question-circle"></i>-->
<!--        </button>-->
<!--        <ng-container *ngIf="user$ | async as user">-->
<!--          <h5 class="m-0 me-3">{{user.email}}</h5>-->
<!--        </ng-container>-->


<!--      </div>-->
      <!--        TODO: USE THIS WHEN NO PICTURE AVAILABLE-->
      <div class="rounded-circle border d-flex justify-content-center align-items-center avatar-img"
           alt="Avatar" *ngIf="false">
        <i class="bi bi-person"></i>
      </div>

      <!-- Search form -->
      <!--      <form class="d-flex input-group w-auto">-->
      <!--        <input-->
      <!--          type="search"-->
      <!--          class="form-control"-->
      <!--          placeholder="Type query"-->
      <!--          aria-label="Search" />-->
      <!--        <button-->
      <!--          class="btn btn-outline-primary"-->
      <!--          type="button"-->
      <!--          data-mdb-ripple-color="dark">-->
      <!--          Search-->
      <!--        </button>-->
      <!--      </form>-->
    </div>

    <ng-template #publicMenu>
      <div [class.collapse]="navbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Left links -->
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0 pb-md-3 pt-md-3">
          <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
            <a class="nav-link" aria-current="page" href="https://www.freefloat.llc/ourteam" target="_blank">About</a>
          </li>
          <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
            <a class="nav-link" aria-current="page" href="#" routerLink="/auth/login"
            >Login</a
            >
          </li>
          <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
            <a
              class="nav-link"
              aria-current="page"
              href="#"
              routerLink="/subscribe"
            >Plans</a
            >
          </li>
          <li class="nav-item text-center px-3"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavbarCollapsing()">
            <a
              class="nav-link"
              aria-current="page"
              href="https://www.freefloat.llc/methodologies-faq" target="_blank"
            >FAQ</a>
          </li>

        </ul>
        <div class="text-center">
          <button type="button" href="#" routerLink="/auth/registration"
                  class="btn btn-primary ps-4 pe-4">Register For a free Plan
          </button>
        </div>
        <!-- Left links -->
        <!--      <div class="d-flex align-items-center">-->
        <!--        <button class="btn rounded-0 me-1" type="button" (click)="openHelpPage()" [matTooltip]="'Methodology Documents'" matTooltipPosition="below">-->
        <!--          <i class="bi bi-question-circle"></i>-->
        <!--        </button>-->
        <!--        <ng-container *ngIf="user$ | async as user">-->
        <!--          <h5 class="m-0 me-3">{{user.email}}</h5>-->
        <!--        </ng-container>-->


        <!--      </div>-->
        <!--        TODO: USE THIS WHEN NO PICTURE AVAILABLE-->
        <div class="rounded-circle border d-flex justify-content-center align-items-center avatar-img"
             alt="Avatar" *ngIf="false">
          <i class="bi bi-person"></i>
        </div>

        <!-- Search form -->
        <!--      <form class="d-flex input-group w-auto">-->
        <!--        <input-->
        <!--          type="search"-->
        <!--          class="form-control"-->
        <!--          placeholder="Type query"-->
        <!--          aria-label="Search" />-->
        <!--        <button-->
        <!--          class="btn btn-outline-primary"-->
        <!--          type="button"-->
        <!--          data-mdb-ripple-color="dark">-->
        <!--          Search-->
        <!--        </button>-->
        <!--      </form>-->
      </div>
    </ng-template>

    <!-- Collapsible wrapper -->
  </div>
  <!-- Container wrapper -->
</nav>
