<footer class="bg-primary text-white text-center text-lg-start">
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row mt-4 mb-4">
      <!--Grid column-->
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <h5 class="text-uppercase">Contact</h5>
        <a href="mailto:inquiries@freefloat.llc" class="text-white">inquiries@freefloat.llc</a>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Navigate</h5>

        <ul class="list-unstyled list-group list-group-horizontal mb-0">
          <li class="list-group-item-light me-4">
            <a href="https://www.freefloat.llc/ourteam" class="text-white" target="_blank">About</a>
          </li>
          <li class="list-group-item-light me-4">
            <a href="https://www.freefloat.llc/disclaimer" class="text-white" target="_blank">Disclaimer</a>
          </li>
          <li class="list-group-item-light me-4">
            <a href="https://www.freefloat.llc/terms-and-conditions" class="text-white" target="_blank">Terms & Conditions</a>
          </li>
          <li class="list-group-item-light me-4">
            <a href="https://www.freefloat.llc/privacy-policy" class="text-white" target="_blank">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->

<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
<!--      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">-->
<!--        <h5 class="text-uppercase mb-0">Links</h5>-->

<!--        <ul class="list-unstyled">-->
<!--          <li>-->
<!--            <a href="#!" class="text-white">Link 1</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="#!" class="text-white">Link 2</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="#!" class="text-white">Link 3</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a href="#!" class="text-white">Link 4</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      &lt;!&ndash;Grid column&ndash;&gt;-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © {{ currentDateStr }} Copyright:
    <a class="text-white" href="https://www.freefloat.llc"
      >Free Float Analytics</a
    >
  </div>
  <!-- Copyright -->
</footer>
