import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as PortfolioActions from './portfolio.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class PortfolioEffects {
  loadPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolio),
      mergeMap(action =>
        this.api.getPortfolio(action.page, action.search, action.limit).pipe(
          map(response => {
            return PortfolioActions.loadPortfolioSuccess({
              portfolio: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(PortfolioActions.loadPortfolioFailure(error)))
        )
      )
    )
  );
  loadPortfolioByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolioByCompanyId),
      mergeMap(action =>
        this.api.getCompanyFromPortfolio(action.id_company).pipe(
          map(response => {
            return PortfolioActions.loadPortfolioByCompanyIdSuccess({
              portfolio: response,
            });
          }),
          catchError(error =>
            of(PortfolioActions.loadPortfolioByCompanyIdFailure(error))
          )
        )
      )
    )
  );

  addCompanyToPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.addCompanyToPortfolio),
      mergeMap(action =>
        this.api.addCompanyToPortfolio(action.id_company).pipe(
          map(response => {
            return PortfolioActions.addCompanyToPortfolioSuccess({
              portfolio: response,
            });
          }),
          catchError(error =>
            of(PortfolioActions.addCompanyToPortfolioFailure(error))
          )
        )
      )
    )
  );

  deletePortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.deletePortfolio),
      mergeMap(action =>
        this.api.deletePortfolio(action.id).pipe(
          map(() => {
            return PortfolioActions.deletePortfolioSuccess();
          }),
          catchError(error =>
            of(PortfolioActions.deletePortfolioFailure(error))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: FfaService) {}
}
