import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as DirectorshipActions from './directorship.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class DirectorshipEffects {
  loadDirectorship$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectorshipActions.loadDirectorships),
      mergeMap(action =>
        this.api.getDirectorships(action.page, action.search).pipe(
          map(response => {
            return DirectorshipActions.loadDirectorshipsSuccess({
              directorships: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error =>
            of(DirectorshipActions.loadDirectorshipsFailure(error))
          )
        )
      )
    )
  );
  constructor(private actions$: Actions, private api: FfaService) {}
}
