import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'subscribe',
    loadChildren: () =>
      import('./modules/subscribe/subscribe.module').then(
        m => m.SubscribeModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/layout/layout.module').then(m => m.LayoutModule),
  },
  // {
  //   path: 'basic_login',
  //   loadChildren: () =>
  //     import('./pages/basic-login/basic-login.module').then(
  //       m => m.BasicLoginModule
  //     ),
  // },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
