import { createAction, props } from '@ngrx/store';
import { Company } from '../../models';
import { ChartNetwork } from '../../models/chart-network';
import { ChartPerformance } from '../../models/chart-performance';
import { ChartInfluence } from '../../models/chart-influence';
export const loadCompanies = createAction(
  '[Company] Load Companies',
  props<{ page?: number; limit?: number; search?: string }>()
);

export const loadCompaniesSuccess = createAction(
  '[Company] Load Companies Success',
  props<{
    companies: Company[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);
export const loadTopCompanies = createAction(
  '[Company] Load Top Companies',
  props<{ page?: number; limit?: number; search?: string }>()
);

export const loadTopCompaniesSuccess = createAction(
  '[Company] Load Top Companies Success',
  props<{
    companies: Company[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadCompaniesFailure = createAction(
  '[Company] Load Companies Failure',
  props<{
    error: any;
  }>()
);

export const selectCompany = createAction(
  '[Company] Select Company',
  props<{ id: number }>()
);

export const loadCompanyById = createAction(
  '[Company] Load Company By Id',
  props<{ id: number }>()
);
export const loadCompanyByIdSuccess = createAction(
  '[Company] Load Company By Id Success',
  props<{ company: Company }>()
);
export const loadCompanyByIdFailure = createAction(
  '[Company] Load Company By Id Failure',
  props<{ error: any }>()
);

export const loadNetworkChart = createAction(
  '[Company] Load Company Chart Network',
  props<{ id: number }>()
);
export const loadNetworkChartSuccess = createAction(
  '[Company] Load Company Chart Network Success',
  props<{ chart: ChartNetwork }>()
);
export const loadNetworkChartFailure = createAction(
  '[Company] Load Company Chart Network Failure',
  props<{ error: any }>()
);

export const loadPerformanceChart = createAction(
  '[Company] Load Company Chart Performance',
  props<{ id: number }>()
);
export const loadPerformanceChartSuccess = createAction(
  '[Company] Load Company Chart Performance Success',
  props<{ chart: ChartPerformance }>()
);
export const loadPerformanceChartFailure = createAction(
  '[Company] Load Company Chart Performance Failure',
  props<{ error: any }>()
);

export const loadInfluenceChart = createAction(
  '[Company] Load Company Chart Influence',
  props<{ id: number }>()
);
export const loadInfluenceChartSuccess = createAction(
  '[Company] Load Company Chart Influence Success',
  props<{ chart: ChartInfluence }>()
);
export const loadInfluenceChartFailure = createAction(
  '[Company] Load Company Chart Influence Failure',
  props<{ error: any }>()
);

export const resetSelectedCompany = createAction(
  '[Company] Reset Selected Company'
);
