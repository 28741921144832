import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LayoutService } from '../../core/layout.service';
import { AuthService, UserType } from '../../../auth';
import { methodology } from '../../../../core/constants/methodology';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  headerContainerCssClasses = '';
  asideDisplay = true;
  headerLeft = 'menu';
  pageTitleCssClasses = '';
  isPremiumPlansEnabled: boolean = environment.isPremiumPlansEnabled;
  user$: Observable<UserType> =
    this.authService.currentUserSubject.asObservable();
  pageTitleAttributes: {
    [attrName: string]: string | boolean;
  };

  @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;

  private readonly unsubscribe: Subscription[] = [];

  constructor(
    private readonly layout: LayoutService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.routingChanges();
  }

  navbarCollapsed = true;

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  ngOnInit(): void {
    this.headerContainerCssClasses =
      this.layout.getStringCSSClasses('headerContainer');
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.headerLeft = this.layout.getProp('header.left') as string;
    this.pageTitleCssClasses = this.layout.getStringCSSClasses('pageTitle');
    this.pageTitleAttributes = this.layout.getHTMLAttributes('pageTitle');
  }

  ngAfterViewInit(): void {
    if (this.ktPageTitle) {
      for (const key in this.pageTitleAttributes) {
        if (this.pageTitleAttributes.hasOwnProperty(key)) {
          this.ktPageTitle.nativeElement.attributes[key] =
            this.pageTitleAttributes[key];
        }
      }
    }
  }

  routingChanges(): void {
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // todo: MenuComponent.reinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  openHelpPage(event: Event) {
    event.preventDefault();
    window.open('https://www.freefloat.llc/methodologies-faq', '_blank');
  }

  logOut(event: Event) {
    event.preventDefault();
    this.authService.logout();
  }

  protected readonly methodology = methodology;
}
