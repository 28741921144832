import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as WatchlistActions from './watchlist.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class WatchlistEffects {
  loadWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WatchlistActions.loadWatchlist),
      mergeMap(action =>
        this.api.getWatchlist(action.page, action.search, action.limit).pipe(
          map(response => {
            return WatchlistActions.loadWatchlistSuccess({
              watchlist: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(WatchlistActions.loadWatchlistFailure(error)))
        )
      )
    )
  );
  loadWatchlistByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WatchlistActions.loadWatchlistByCompanyId),
      mergeMap(action =>
        this.api.getCompanyFromWatchlist(action.id_company).pipe(
          map(response => {
            return WatchlistActions.loadWatchlistByCompanyIdSuccess({
              watchlist: response,
            });
          }),
          catchError(error =>
            of(WatchlistActions.loadWatchlistByCompanyIdFailure(error))
          )
        )
      )
    )
  );

  addCompanyToWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WatchlistActions.addCompanyToWatchlist),
      mergeMap(action =>
        this.api.addCompanyToWatchlist(action.id_company).pipe(
          map(response => {
            return WatchlistActions.addCompanyToWatchlistSuccess({
              watchlist: response,
            });
          }),
          catchError(error =>
            of(WatchlistActions.addCompanyToWatchlistFailure(error))
          )
        )
      )
    )
  );

  deleteWatchlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WatchlistActions.deleteWatchlist),
      mergeMap(action =>
        this.api.deleteWatchlist(action.id).pipe(
          map(() => {
            return WatchlistActions.deleteWatchlistSuccess();
          }),
          catchError(error =>
            of(WatchlistActions.deleteWatchlistFailure(error))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: FfaService) {}
}
