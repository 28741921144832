import { createAction, props } from '@ngrx/store';
import { Portfolio } from '../../models';
export const loadPortfolio = createAction(
  '[Portfolio] Load Portfolio',
  props<{ page?: number; limit?: number; search?: string }>()
);

export const loadPortfolioSuccess = createAction(
  '[Portfolio] Load Portfolio Success',
  props<{
    portfolio: Portfolio[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadPortfolioFailure = createAction(
  '[Portfolio] Load Portfolio Failure',
  props<{
    error: any;
  }>()
);

export const selectPortfolio = createAction(
  '[Portfolio] Select Portfolio',
  props<{ id: number }>()
);

export const loadPortfolioByCompanyId = createAction(
  '[Portfolio] Load Portfolio By Company Id',
  props<{ id_company: number }>()
);
export const loadPortfolioByCompanyIdSuccess = createAction(
  '[Portfolio] Load Portfolio By Company Id Success',
  props<{ portfolio: Portfolio }>()
);
export const loadPortfolioByCompanyIdFailure = createAction(
  '[Portfolio] Load Portfolio By Company Id Failure',
  props<{ error: any }>()
);

export const addCompanyToPortfolio = createAction(
  '[Portfolio] addCompanyToPortfolio',
  props<{ id_company: number }>()
);
export const addCompanyToPortfolioSuccess = createAction(
  '[Portfolio] addCompanyToPortfolio Success',
  props<{ portfolio: Portfolio }>()
);
export const addCompanyToPortfolioFailure = createAction(
  '[Portfolio] addCompanyToPortfolio Failure',
  props<{ error: any }>()
);

export const deletePortfolio = createAction(
  '[Portfolio] deletePortfolio',
  props<{ id: number }>()
);
export const deletePortfolioSuccess = createAction(
  '[Portfolio] deletePortfolio Success'
);
export const deletePortfolioFailure = createAction(
  '[Portfolio] deletePortfolio Failure',
  props<{ error: any }>()
);
