import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as PodcastActions from './podcast.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class PodcastEffects {
  loadPodcasts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PodcastActions.loadPodcasts),
      mergeMap(action =>
        this.api.getPodcasts(action.page).pipe(
          map(response => {
            return PodcastActions.loadPodcastsSuccess({
              podcasts: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(PodcastActions.loadPodcastsFailure(error)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: FfaService) {}
}
