export interface methodologyInterface {
  average_age: string;
  overall: string;
  ebitda: string;
  carbon_intensity: string;
  tsr: string;
  controversies: string;
  gender_influence_gap: string;
  diversity_influence_gap: string;
  director_batting_average: string;
  average_total_influence: string;
  team_power: string;
  ceo: string;
  board_demo: string;
  board_pay: string;
  biggest_influencer: string;
  board_type: string;
  network_concentration: string;
  board_strength: string;
  board_weakness: string;
  director_age: string;
  director_gender: string;
  director_influence: string;
  chart_board_influence: string;
  chart_board_performance: string;
  chart_network: string;
}

export const methodology: methodologyInterface = {
  average_age:
    'Influence weighted age of all directors’ current age (“decision-making age”).',
  overall:
    '“Win rate” for directors versus peers, weighted by director influence, where average directors are between 0.400 and 0.600.  Overall win rate includes ranking versus peers on EBITDA, carbon intensity, TSR, and controversies.',
  ebitda:
    '“Win rate” for directors versus peers on Earning Before Income Tax, Depreciation, and Amortization (EBITDA).  Think of it as the percentage of times the director “beat” their peers where an average director is between 0.400 and 0.600.',
  carbon_intensity:
    '“Win rate” for directors versus peers on carbon intensity (scope 1 and 2 emissions per USDm of revenue). Think of it as the percentage of times the director “beat” their peers where an average director is between 0.400 and 0.600.',
  tsr: '“Win rate” for directors versus peers on total shareholder returns (TSR). Think of it as the percentage of times the director “beat” their peers where an average director is between 0.400 and 0.600.',
  controversies:
    '“Win rate” for directors versus peers on controversies (count of times their companies are in the news for terrible stuff). Think of it as the percentage of times the director “beat” their peers where an average director is between 0.400 and 0.600.',
  gender_influence_gap:
    'The difference between influence (the percentage of influence women control) and body count (percentage of women on the board).  Think of it as a “power gap” that describes inclusion vs. representation.',
  diversity_influence_gap:
    'The difference between influence (the percentage of influence diverse directors control) and body count (the percentage of diverse directors on the board).  Think of it as a “power gap” that describes inclusion vs. representation.',
  director_batting_average:
    '“Win rate” for directors versus peers, where average directors are between 0.400 and 0.600 over the last five to seven years.  Director Batting Average includes ranking versus peers on EBITDA, carbon intensity, TSR, and controversies.',
  average_total_influence:
    'The influence that this director holds on the board of the company identified for the respective year.',
  team_power:
    '“Win rate” for directors versus peers, weighted by director influence, where average directors are between 0.400 and 0.600.  Overall win rate includes ranking versus peers on EBITDA, carbon intensity, TSR, and controversies.',
  ceo: 'Current CEO of the company.',
  board_demo:
    'Active directors identified as female as a percentage of total active directors serving on the company’s board.',
  board_pay:
    'Summation of active directors’ compensation, including equity awards and cash payment.',
  biggest_influencer: 'Director with the highest influencer on the board.',
  board_type:
    'If the board were a government, the style of government it would most likely be.  Uses influence consolidation, performance, and demographics to determine.',
  network_concentration:
    'The percentage of influence controlled by directors who are connected in two phone calls or less via other boards, prior jobs, or other affiliations.',
  board_strength:
    'Weighted by director influence, the strongest performance metric for active board members.',
  board_weakness:
    'Weighted by director influence, the weakest performance metric for the active board members.',
  director_age: 'Director’s current disclosed age',
  director_gender: 'Director’s current disclosed gender',
  director_influence:
    'The percentage of influence the director has over this board using resume, status, role, and network data in the context of this particular team.',
  chart_board_influence:
    'The active board pie chart of influence, represents how influence is dispersed amongst board members.',
  chart_board_performance:
    'Board “win rates” compared to sector peers of a similar size across overall, EBITDA, carbon intensity, TSR, and controversies.',
  chart_network:
    'The percentage of board members active on the board connected in two phone calls or less via other boards, prior jobs, or other affiliations.',
};
