import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';
import { SubscriptionModel } from '../models/subscription.model';
import { environment } from '../../../../environments/environment';

export type UserType = UserModel | undefined;
export type UserSubscription = SubscriptionModel | null;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private readonly unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  // public fields
  isLoading$: Observable<boolean> = new BehaviorSubject<boolean>(false);
  currentUserSubject = new BehaviorSubject<UserType>(undefined);
  currentSubscriptionSubject = new BehaviorSubject<UserSubscription>(null);
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isPremiumPlansEnabled: boolean = environment.isPremiumPlansEnabled;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  constructor(
    private readonly authHttpService: AuthHTTPService,
    private readonly router: Router,
    private readonly tokenStorage: TokenStorageService
  ) {
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  hasActivePlan(plans: Array<'standard' | 'pro' | 'enterprise'>): boolean {
    if (!this.isPremiumPlansEnabled) {
      return true;
    }
    const user = this.currentUserSubject.value;

    return (
      user?.subscriptions.some(
        subscription =>
          plans.includes(subscription.name) &&
          (subscription.stripe_status === 'trialing' ||
            subscription.stripe_status === 'active') &&
          (!subscription.ends_at ||
            new Date(subscription.ends_at) >= new Date()) &&
          (!subscription.trial_ends_at ||
            new Date(subscription.trial_ends_at) >= new Date())
      ) ?? false
    );
  }
  getActivePlan(): UserSubscription | undefined {
    const user = this.currentUserSubject.value;

    const currentDate = new Date().getTime();
    return user?.subscriptions.find(subscription => {
      // Parse date strings to Date objects
      const trialEndsAt = subscription.trial_ends_at
        ? new Date(subscription.trial_ends_at).getTime()
        : null;
      const endsAt = subscription.ends_at
        ? new Date(subscription.ends_at).getTime()
        : null;
      return (
        (trialEndsAt === null || trialEndsAt > currentDate) &&
        (endsAt === null || endsAt > currentDate)
      );
    });
  }

  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        return this.setAuthFromLocalStorage(auth);
      }),
      switchMap(() => this.getUserByToken()),
      catchError(err => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(): void {
    this.tokenStorage.signOut();
    this.router
      .navigate(['/auth/login'], {
        queryParams: {},
      })
      .then()
      .catch(error => {
        console.log(error);
      });
  }

  getUserByToken(): Observable<UserType> {
    const access_token = this.tokenStorage.getAccessToken();
    if (!access_token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(access_token).pipe(
      map((user: UserType) => {
        if (user != null) {
          this.currentUserSubject.next(user);
          this.currentSubscriptionSubject.next(user.subscription ?? null);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError(err => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(email, token, password, password_confirmation)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth?.access_token) {
      this.tokenStorage.saveToken(auth.access_token);
      this.tokenStorage.saveRefreshToken(auth.access_token);
      this.tokenStorage.saveUser(auth);
      return true;
    }
    return false;
  }
  getAccessToken(): string | null {
    return this.tokenStorage.getAccessToken();
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
