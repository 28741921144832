import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as PodcastActions from './podcast.actions';
import { Podcast } from '../../models';

export interface PodcastState extends EntityState<Podcast> {
  selectedPodcastId: number | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const podcastAdapter: EntityAdapter<Podcast> =
  createEntityAdapter<Podcast>({
    selectId: (podcast: Podcast) => podcast.id,
    sortComparer: false,
  });

export const initialState: PodcastState = podcastAdapter.getInitialState({
  selectedPodcastId: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  loading: false,
});

export const podcastReducer = createReducer(
  initialState,
  on(PodcastActions.loadPodcasts, state => {
    return { ...state, loading: true };
  }),
  on(
    PodcastActions.loadPodcastsSuccess,
    (state, { podcasts, currentPage, totalPages, totalItems }) =>
      podcastAdapter.setAll([...selectAllPodcasts(state), ...podcasts], {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      })
  ),
  on(PodcastActions.selectPodcast, (state, { id }) => {
    return { ...state, selectedPodcastId: id };
  })
);

export function reducer(state: PodcastState | undefined, action: Action) {
  return podcastReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  podcastAdapter.getSelectors();

// select the array of user ids
export const selectPodcastIds = selectIds;

// select the dictionary of user entities
export const selectPodcastEntities = selectEntities;

// select the array of users
export const selectAllPodcasts = selectAll;

// select the total user count
export const selectPodcastTotal = selectTotal;
