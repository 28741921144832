<div class="bg-light min-vh-100 d-flex flex-column justify-content-between">
  <div>
    <app-header *ngIf="layout.headerVisible | async"
      id="kt_header"
      [ngClass]="headerCSSClasses"
      class="header align-items-stretch"
      #ktHeader></app-header>
    <app-content id="kt_content_container" class="content"></app-content>
  </div>

  <app-footer class="footer" id="kt_footer"></app-footer>
</div>

<!-- Pop-Under Paywall -->
<ng-container *ngIf="layout.paywallVisible | async as paywallVisible">
  <div class="paywall container-fluid" id="popUnderPaywall" [@blinkAnimation]="paywallVisible">
    <div class="row d-flex justify-content-end">
      <button type="button" class="btn-close" aria-label="Close" (click)="closePayWall()"></button>
    </div>
    <div class="container-fluid-boxed">
      <h1>{{layout.paywallTitle | async}}</h1>
      <h2>{{layout.paywallSubTitle | async}}</h2>

      <div class="d-flex justify-content-evenly d-none d-sm-flex">
        <div class="feature">
          <i class="bi bi-printer"></i>
          <p class="h3">Excel exports & PDF prints</p>
        </div>
        <div class="feature">
          <i class="bi bi-globe"></i>
          <p class="h3">Unlimited access to 9,000+ companies</p>
        </div>
        <div class="feature">
          <i class="bi bi-pie-chart"></i>
          <p class="h3">Portfolio & Watchlist Creation</p>
        </div>
      </div>

      <div class="paywall-features-mobile d-lg-none">
        <ul class="list-group mb-4">
          <li href="#" class="list-group-item border-0"><i class="fa fa-check-circle text-primary" aria-hidden="true"></i>
            Unlimited access to 9,000+ companies
          </li>
          <li href="#" class="list-group-item border-0"><i class="fa fa-check-circle text-primary" aria-hidden="true"></i>
            Portfolio & Watchlist Creation
          </li>
          <li href="#" class="list-group-item border-0"><i class="fa fa-check-circle text-primary" aria-hidden="true"></i>
            Excel exports & PDF prints
          </li>
        </ul>
      </div>
      <button type="button" href="#" routerLink="/subscribe"
              class="btn btn-lg btn-primary ps-4 pe-4 mb-3">Subscribe Now
      </button>
      <p>Need help? <a href="https://www.freefloat.llc/methodologies-faq" target="_blank"><span>Visit our FAQ section!</span></a></p>
    </div>
  </div>
</ng-container>
<app-scripts-init></app-scripts-init>
<!--<app-scroll-top></app-scroll-top>-->

<!-- end:: Engage
<app-engages></app-engages>
 end:: Engage -->

<!-- begin:: Modals -->
<!--<app-main-modal></app-main-modal>-->
<!-- end:: Modals -->
