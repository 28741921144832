import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { LayoutService } from './core/layout.service';
import { LayoutInitService } from './core/layout-init.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('blinkAnimation', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('true <=> false', animate('200ms')),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  contentContainerClasses = '';
  toolbarDisplay = true;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;
  constructor(
    readonly initService: LayoutInitService,
    readonly layout: LayoutService
  ) {
    this.initService.init();
    // this.headerVisible = this.layout.headerVisible;
  }

  ngOnInit(): void {
    // build view by layout config settings
    this.contentContainerClasses =
      this.layout.getStringCSSClasses('contentContainer');
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('headerMenu');
    // this.layout.headerVisible.subscribe(state => (this.message = message));
  }

  ngAfterViewInit(): void {
    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
  }

  closePayWall() {
    this.layout.hidePaywall();
  }
}
