import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as DirectorActions from './director.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class DirectorEffects {
  loadDirectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectorActions.loadDirectors),
      mergeMap(action =>
        this.api.getDirectors(action.page, action.search, action.limit).pipe(
          map(response => {
            return DirectorActions.loadDirectorsSuccess({
              directors: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(DirectorActions.loadDirectorsFailure(error)))
        )
      )
    )
  );

  loadBiggestInfluencers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectorActions.loadBiggestInfluencers),
      mergeMap(action =>
        this.api.getBiggestInfluencers(action.page).pipe(
          map(response => {
            return DirectorActions.loadBiggestInfluencersSuccess({
              directors: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error =>
            of(DirectorActions.loadBiggestInfluencersFailure(error))
          )
        )
      )
    )
  );

  loadDirectorById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectorActions.loadDirectorById),
      mergeMap(action =>
        this.api.getDirector(action.id).pipe(
          map(response => {
            return DirectorActions.loadDirectorByIdSuccess({
              director: response,
            });
          }),
          catchError(error =>
            of(DirectorActions.loadDirectorByIdFailure(error))
          )
        )
      )
    )
  );

  loadDirectorsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectorActions.loadDirectorsById),
      mergeMap(action =>
        this.api.getDirectorsByIds(action.ids).pipe(
          map(response => {
            return DirectorActions.loadDirectorsByIdSuccess({
              directors: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error =>
            of(DirectorActions.loadDirectorsByIdFailure(error))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: FfaService) {}
}
