import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as DirectorActions from './director.actions';
import { Director } from '../../models';

type Nullable<T> = T | null;

export interface DirectorState extends EntityState<Director> {
  selectedDirector: Nullable<Director>;
  selectedDirectors: Nullable<Director[]>;
  biggestInfluencers: Array<Director>;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const directorAdapter: EntityAdapter<Director> =
  createEntityAdapter<Director>({
    selectId: (director: Director) => director.id,
    sortComparer: false,
  });

export const initialState: DirectorState = directorAdapter.getInitialState({
  currentPage: 1,
  biggestInfluencers: [],
  totalPages: 1,
  totalItems: 0,
  loading: false,
  selectedDirector: null,
  selectedDirectors: null,
});

export const directorsReducer = createReducer(
  initialState,
  on(DirectorActions.loadDirectors, state => {
    return { ...state, loading: true };
  }),
  on(
    DirectorActions.loadDirectorsSuccess,
    (state, { directors, currentPage, totalPages, totalItems }) => {
      const entities =
        currentPage === 1
          ? [...directors]
          : [...selectAllDirectors(state), ...directors];
      return directorAdapter.setAll(entities, {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      });
    }
  ),
  on(DirectorActions.selectDirector, (state, { id }) => {
    return { ...state, selectedDirectorId: id };
  }),
  on(DirectorActions.loadDirectorById, state => ({ ...state, loading: true })),
  on(DirectorActions.loadDirectorByIdSuccess, (state, { director }) => ({
    ...state,
    selectedDirector: director,
    loading: false,
    error: null,
  })),
  on(DirectorActions.loadDirectorByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(DirectorActions.resetSelectedDirector, state => ({
    ...state,
    selectedDirector: null,
  })),
  on(DirectorActions.loadDirectorsById, state => ({ ...state, loading: true })),
  on(
    DirectorActions.loadDirectorsByIdSuccess,
    (state, { directors, currentPage, totalPages, totalItems }) => ({
      ...state,
      selectedDirectors: directors,
      loading: false,
      error: null,
    })
  ),
  on(DirectorActions.loadDirectorsByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(DirectorActions.resetSelectedDirectors, state => ({
    ...state,
    selectedDirectors: null,
  })),
  on(DirectorActions.loadBiggestInfluencersSuccess, (state, { directors }) => ({
    ...state,
    biggestInfluencers: directors,
    loading: false,
    error: null,
  }))
);

export function reducer(state: DirectorState | undefined, action: Action) {
  return directorsReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  directorAdapter.getSelectors();

// select the array of user ids
export const selectDirectorIds = selectIds;

// select the dictionary of user entities
export const selectDirectorEntities = selectEntities;

// select the array of users
export const selectAllDirectors = selectAll;

// select the total user count
export const selectDirectorTotal = selectTotal;
