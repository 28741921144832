import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of, tap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import * as CompanyActions from './company.actions';
import { FfaService } from '../../services/ffa.service';
import { LayoutService } from '../../../modules/layout';
import { resetSelectedCompany } from './company.actions';

@Injectable()
export class CompanyEffects {
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompanies),
      mergeMap(action =>
        this.api.getCompanies(action.page, action.search, action.limit).pipe(
          map(response => {
            return CompanyActions.loadCompaniesSuccess({
              companies: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(CompanyActions.loadCompaniesFailure(error)))
        )
      )
    )
  );
  loadTopCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadTopCompanies),
      mergeMap(action =>
        this.api.getTopCompanies(action.page, action.search, action.limit).pipe(
          map(response => {
            return CompanyActions.loadTopCompaniesSuccess({
              companies: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(CompanyActions.loadCompaniesFailure(error)))
        )
      )
    )
  );

  loadCompanyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompanyById),
      mergeMap(action =>
        this.api.getCompany(action.id).pipe(
          map(response => {
            return CompanyActions.loadCompanyByIdSuccess({
              company: response,
            });
          }),
          catchError(error => of(CompanyActions.loadCompanyByIdFailure(error)))
        )
      )
    )
  );

  loadCompanyByIdSuccessShowPaywall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyActions.loadCompanyByIdSuccess),
        map(action => action.company),
        filter(company => company.is_premium), // Only proceed if is_premium is true
        tap(() => {
          this.layout.showPaywall(); // Call the layout.showPaywall() method.
        })
      ),
    { dispatch: false }
  );

  resetSelectedCompanyHidePaywall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyActions.resetSelectedCompany),
        tap(() => {
          this.layout.hidePaywall(); // Call the layout.hidePaywall() method.
        })
      ),
    { dispatch: false }
  );

  loadNetworkChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadNetworkChart),
      mergeMap(action =>
        this.api.getCompanyNetworkChart(action.id).pipe(
          map(response => {
            return CompanyActions.loadNetworkChartSuccess({
              chart: response,
            });
          }),
          catchError(error => of(CompanyActions.loadNetworkChartFailure(error)))
        )
      )
    )
  );

  loadPerformanceChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadPerformanceChart),
      mergeMap(action =>
        this.api.getCompanyPerformanceChart(action.id).pipe(
          map(response => {
            return CompanyActions.loadPerformanceChartSuccess({
              chart: response,
            });
          }),
          catchError(error =>
            of(CompanyActions.loadPerformanceChartFailure(error))
          )
        )
      )
    )
  );

  loadInfluenceChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadInfluenceChart),
      mergeMap(action =>
        this.api.getCompanyInfluenceChart(action.id).pipe(
          map(response => {
            return CompanyActions.loadInfluenceChartSuccess({
              chart: response,
            });
          }),
          catchError(error =>
            of(CompanyActions.loadInfluenceChartFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private api: FfaService,
    private layout: LayoutService
  ) {}
}
