import { createAction, props } from '@ngrx/store';
import { Watchlist } from '../../models';
export const loadWatchlist = createAction(
  '[Watchlist] Load Watchlist',
  props<{ page?: number; limit?: number; search?: string }>()
);

export const loadWatchlistSuccess = createAction(
  '[Watchlist] Load Watchlist Success',
  props<{
    watchlist: Watchlist[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadWatchlistFailure = createAction(
  '[Watchlist] Load Watchlist Failure',
  props<{
    error: any;
  }>()
);

export const selectWatchlist = createAction(
  '[Watchlist] Select Watchlist',
  props<{ id: number }>()
);

export const loadWatchlistByCompanyId = createAction(
  '[Watchlist] Load Watchlist By Company Id',
  props<{ id_company: number }>()
);
export const loadWatchlistByCompanyIdSuccess = createAction(
  '[Watchlist] Load Watchlist By Company Id Success',
  props<{ watchlist: Watchlist }>()
);
export const loadWatchlistByCompanyIdFailure = createAction(
  '[Watchlist] Load Watchlist By Company Id Failure',
  props<{ error: any }>()
);

export const addCompanyToWatchlist = createAction(
  '[Watchlist] addCompanyToWatchlist',
  props<{ id_company: number }>()
);
export const addCompanyToWatchlistSuccess = createAction(
  '[Watchlist] addCompanyToWatchlist Success',
  props<{ watchlist: Watchlist }>()
);
export const addCompanyToWatchlistFailure = createAction(
  '[Watchlist] addCompanyToWatchlist Failure',
  props<{ error: any }>()
);

export const deleteWatchlist = createAction(
  '[Watchlist] deleteWatchlist',
  props<{ id: number }>()
);
export const deleteWatchlistSuccess = createAction(
  '[Watchlist] deleteWatchlist Success'
);
export const deleteWatchlistFailure = createAction(
  '[Watchlist] deleteWatchlist Failure',
  props<{ error: any }>()
);
