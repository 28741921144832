import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

export interface PageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export class PageInfo {
  breadcrumbs: PageLink[] = [];
  title = '';
}

@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  get toolbarPrimaryBtnTitle(): Observable<string> {
    return this._toolbarPrimaryBtnTitle.asObservable();
  }

  setToolbarPrimaryBtnTitle(value: string): PageInfoService {
    this._toolbarPrimaryBtnTitle.next(value);
    return this;
  }

  get toolbarSecondaryBtnTitle(): Observable<string> {
    return this._toolbarSecondaryBtnTitle.asObservable();
  }

  setToolbarSecondaryBtnTitle(value: string): PageInfoService {
    this._toolbarSecondaryBtnTitle.next(value);
    return this;
  }

  get toolbarPrimaryBtnPressed(): Observable<string> {
    return this._toolbarPrimaryBtnPressed.asObservable();
  }

  get toolbarSecondaryBtnPressed(): Observable<string> {
    return this._toolbarSecondaryBtnPressed.asObservable();
  }

  public title: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Dashboard'
  );

  public description: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public breadcrumbs: BehaviorSubject<PageLink[]> = new BehaviorSubject<
    PageLink[]
  >([]);

  private readonly _toolbarPrimaryBtnTitle: BehaviorSubject<string> =
    new BehaviorSubject<string>('Create');
  private readonly _toolbarSecondaryBtnTitle: BehaviorSubject<string> =
    new BehaviorSubject<string>('Filter');

  private readonly _toolbarPrimaryBtnPressed: Subject<any> = new Subject<any>();
  private readonly _toolbarSecondaryBtnPressed: Subject<any> =
    new Subject<any>();
  public toolbarPrimaryBtnVisibility: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  public setTitle(_title: string): void {
    this.title.next(_title);
  }

  public togglePrimaryBtn(visible: boolean): void {
    this.toolbarPrimaryBtnVisibility.next(visible);
  }

  public primaryBtnClicked(): void {
    this._toolbarPrimaryBtnPressed.next(null);
  }

  public secondaryBtnClicked(): void {
    this._toolbarPrimaryBtnPressed.next(null);
  }

  public updateTitle(_title: string): void {
    setTimeout(() => {
      this.setTitle(_title);
    }, 1);
  }

  public setDescription(_title: string): void {
    this.description.next(_title);
  }

  public updateDescription(_description: string): void {
    setTimeout(() => {
      this.setDescription(_description);
    }, 1);
  }

  public setBreadcrumbs(_bs: PageLink[]): void {
    this.breadcrumbs.next(_bs);
  }

  public updateBreadcrumbs(_bs: PageLink[]): void {
    setTimeout(() => {
      this.setBreadcrumbs(_bs);
    }, 20);
  }

  public calculateTitle(): void {
    const asideTitle = this.calculateTitleInMenu('asideMenu');
    const headerTitle = this.calculateTitleInMenu('#kt_header_menu');
    const title = asideTitle || headerTitle || '';
    this.setTitle(title);
  }

  public calculateTitleInMenu(menuId: string): string | undefined {
    const menu = document.getElementById(menuId);
    if (menu == null) {
      return;
    }

    const allActiveMenuLinks = Array.from<HTMLLinkElement>(
      menu.querySelectorAll('a.menu-link')
    ).filter(link => link.classList.contains('active'));

    if (!allActiveMenuLinks || allActiveMenuLinks.length === 0) {
      return;
    }

    const titleSpan: HTMLHeadingElement | null =
      allActiveMenuLinks[0].querySelector('span.menu-title');
    if (titleSpan == null) {
      return;
    }

    return titleSpan.innerText;
  }

  public calculateBreadcrumbs(): void {
    const asideBc = this.calculateBreadcrumbsInMenu('asideMenu');
    const headerBc = this.calculateBreadcrumbsInMenu('#kt_header_menu');
    const bc = asideBc != null && asideBc.length > 0 ? asideBc : headerBc;
    if (bc == null) {
      this.setBreadcrumbs([]);
      return;
    }
    this.setBreadcrumbs(bc);
  }

  public calculateBreadcrumbsInMenu(menuId: string): PageLink[] | undefined {
    const result: PageLink[] = [];
    const menu = document.getElementById(menuId);
    if (menu == null) {
      return;
    }

    const allActiveParents = Array.from<HTMLDivElement>(
      menu.querySelectorAll('div.menu-item')
    ).filter(link => link.classList.contains('here'));

    if (!allActiveParents || allActiveParents.length === 0) {
      return;
    }

    allActiveParents.forEach(parent => {
      const titleSpan: HTMLHeadingElement | null =
        parent.querySelector('span.menu-title');
      if (titleSpan == null) {
        return;
      }

      const title = titleSpan.innerText;
      const path = titleSpan.getAttribute('data-link');
      if (!path) {
        return;
      }

      result.push({
        title,
        path,
        isSeparator: false,
        isActive: false,
      });
      // add separator
      result.push({
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      });
    });

    return result;
  }
}
