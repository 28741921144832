import { createAction, props } from '@ngrx/store';
import { CompanyDirectorship } from '../../models';
export const loadDirectorships = createAction(
  '[Directorship] Load Directorships',
  props<{ page?: number; search?: string; limit?: number }>()
);

export const loadDirectorshipsSuccess = createAction(
  '[Directorship] Load Directorships Success',
  props<{
    directorships: CompanyDirectorship[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadDirectorshipsFailure = createAction(
  '[Directorship] Load Directorships Failure',
  props<{
    error: any;
  }>()
);

export const selectDirectorship = createAction(
  '[Directorship] Select Directorship',
  props<{ id: number }>()
);
