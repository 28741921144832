import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ArticleActions from './article.actions';
import { Article } from '../../models';

type Nullable<T> = T | null;

export interface ArticleState extends EntityState<Article> {
  selectedArticle: Nullable<Article>;
  featuredArticle: Nullable<Article>;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
}

export const articleAdapter: EntityAdapter<Article> =
  createEntityAdapter<Article>({
    selectId: (article: Article) => article.id,
    sortComparer: false,
  });

export const initialState: ArticleState = articleAdapter.getInitialState({
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  loading: false,
  selectedArticle: null,
  featuredArticle: null,
});

export const articlesReducer = createReducer(
  initialState,
  on(ArticleActions.loadArticles, state => {
    return { ...state, loading: true };
  }),
  on(
    ArticleActions.loadArticlesSuccess,
    (state, { articles, currentPage, totalPages, totalItems }) =>
      articleAdapter.setAll([...selectAllArticles(state), ...articles], {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      })
  ),
  on(ArticleActions.selectArticle, (state, { id }) => {
    return { ...state, selectedArticleId: id };
  }),
  on(ArticleActions.loadArticleById, state => ({ ...state, loading: true })),

  on(ArticleActions.selectFeaturedArticle, state => ({
    ...state,
    featuredArticle:
      selectAllArticles(state).find(element => {
        return element.visual;
      }) ?? null,
    loading: false,
    error: null,
  })),
  on(ArticleActions.loadArticleByIdSuccess, (state, { article }) => ({
    ...state,
    selectedArticle: article,
    loading: false,
    error: null,
  })),
  on(ArticleActions.loadArticleByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(ArticleActions.resetSelectedArticle, state => ({
    ...state,
    selectedArticle: null,
  }))
);

export function reducer(state: ArticleState | undefined, action: Action) {
  return articlesReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  articleAdapter.getSelectors();

// select the array of user ids
export const selectArticleIds = selectIds;

// select the dictionary of user entities
export const selectArticleEntities = selectEntities;

// select the array of users
export const selectAllArticles = selectAll;

// select the total user count
export const selectArticleTotal = selectTotal;
