import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FfaService } from './core/services/ffa.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { podcastReducer } from './core/store/podcast/podcast.reducer';
import { PodcastEffects } from './core/store/podcast/podcast.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DirectorshipEffects } from './core/store/directorship/directorship.effects';
import { directorshipReducer } from './core/store/directorship/directorship.reducer';
import { directorsReducer } from './core/store/director/director.reducer';
import { DirectorEffects } from './core/store/director/director.effects';
import { companiesReducer } from './core/store/company/company.reducer';
import { CompanyEffects } from './core/store/company/company.effects';
import { watchlistReducer } from './core/store/watchlist/watchlist.reducer';
import { WatchlistEffects } from './core/store/watchlist/watchlist.effects';
import { authInterceptorProviders } from './modules/auth/helpers/auth.interceptor';
import { portfolioReducer } from './core/store/portfolio/portfolio.reducer';
import { PortfolioEffects } from './core/store/portfolio/portfolio.effects';
import { articlesReducer } from './core/store/article/article.reducer';
import { ArticleEffects } from './core/store/article/article.effects';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    MatTableExporterModule,
    StoreModule.forRoot({
      podcasts: podcastReducer,
      directors: directorsReducer,
      directorships: directorshipReducer,
      companies: companiesReducer,
      watchlist: watchlistReducer,
      portfolio: portfolioReducer,
      articles: articlesReducer,
    }),
    EffectsModule.forRoot([
      PodcastEffects,
      DirectorEffects,
      DirectorshipEffects,
      CompanyEffects,
      WatchlistEffects,
      PortfolioEffects,
      ArticleEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    HttpClientModule,
  ],
  providers: [FfaService, authInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
