import { createAction, props } from '@ngrx/store';
import { Article } from '../../models';
export const loadArticles = createAction(
  '[Article] Load Articles',
  props<{ page?: number; limit?: number }>()
);

export const loadArticlesSuccess = createAction(
  '[Article] Load Articles Success',
  props<{
    articles: Article[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadArticlesFailure = createAction(
  '[Article] Load Articles Failure',
  props<{
    error: any;
  }>()
);

export const selectFeaturedArticle = createAction(
  '[Article] Select Featured Article'
);

export const selectArticle = createAction(
  '[Article] Select Article',
  props<{ id: number }>()
);

export const loadArticleById = createAction(
  '[Article] Load Article By Id',
  props<{ id: number }>()
);
export const loadArticleByIdSuccess = createAction(
  '[Article] Load Article By Id Success',
  props<{ article: Article }>()
);
export const loadArticleByIdFailure = createAction(
  '[Article] Load Article By Id Failure',
  props<{ error: any }>()
);

export const resetSelectedArticle = createAction(
  '[Article] Reset Selected Article'
);
