import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Director, Portfolio, Watchlist } from '../models';
import { environment } from '../../../environments/environment';
import { PaginatedResponse } from '../models';
import { Podcast } from '../models';
import { Company } from '../models';
import { Article } from '../models';
import { CompanyDirectorship } from '../models';
import { ChartPerformance } from '../models/chart-performance';
import { ChartNetwork } from '../models/chart-network';
import { ChartInfluence } from '../models/chart-influence';
@Injectable()
export class FfaService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getPodcasts(page = 1): Observable<PaginatedResponse<Podcast>> {
    return this.http.get<PaginatedResponse<Podcast>>(
      `${this.baseURL}podcasts?page=${page}`
    );
  }
  getCompanies(
    page = 1,
    search = '',
    limit = 20
  ): Observable<PaginatedResponse<Company>> {
    let url = `${this.baseURL}companies?with[]=directorships&with[]=directorships.director&page=${page}&per_page=${limit}`;

    if (search && search.trim() !== '') {
      url += `&issuer_name=%25${search}%25&ticker=%25${search}%25&mode=or`;
    }
    return this.http.get<PaginatedResponse<Company>>(url);
  }

  getTopCompanies(
    page = 1,
    search = '',
    limit = 50
  ): Observable<PaginatedResponse<Company>> {
    return this.http.get<PaginatedResponse<Company>>(
      `${this.baseURL}companies?with[]=directorships&with[]=directorships.director&page=${page}&per_page=${limit}&rank=(ge)0&sort[]=rank,asc`
    );
  }

  getBiggestInfluencers(
    page = 1,
    search = '',
    limit = 3
  ): Observable<PaginatedResponse<Director>> {
    return this.http.get<PaginatedResponse<Director>>(
      `${this.baseURL}directors?with[]=directorships&with[]=directorships.company&page=${page}&per_page=${limit}&directorships:biggest_influencer=1&picture=!(null)`
    );
  }
  getDirectors(
    page = 1,
    search = '',
    limit = 3
  ): Observable<PaginatedResponse<Director>> {
    let url = `${this.baseURL}directors?with[]=directorships&with[]=directorships.company&sort[]=network_power_current,desc&sort[]=network_power_2023,desc&page=${page}`;

    if (search && search.trim() !== '') {
      url += `&firstname=%25${search}%25&lastname=%25${search}%25&middlenames=%25${search}%25&mode=or`;
    }
    return this.http.get<PaginatedResponse<Director>>(url);
  }

  getDirectorsByIds(ids: number[]): Observable<PaginatedResponse<Director>> {
    const idsString = ids.join(',');
    return this.http.get<PaginatedResponse<Director>>(
      `${this.baseURL}directors?id=${idsString}with[]=stats&with[]=stats.company&with[]=tags&with[]=tags.tag&limit=${ids.length}`
    );
  }

  getDirectorships(
    page = 1,
    search = ''
  ): Observable<PaginatedResponse<CompanyDirectorship>> {
    let url = `${this.baseURL}directorships?with[]=company&with[]=director&page=${page}&per_page=32`;

    if (search && search.trim() !== '') {
      url += `&search=${search}`;
    }
    return this.http.get<PaginatedResponse<CompanyDirectorship>>(url);
  }

  getDirector(id: number): Observable<Director> {
    return this.http.get<Director>(
      `${this.baseURL}director/${id}?with[]=stats&with[]=stats.company&with[]=tags&with[]=tags.tag`
    );
  }
  getCompany(id: number): Observable<Company> {
    return this.http.get<Company>(
      `${this.baseURL}company/${id}?with[]=director&with[]=sector&with[]=industry&with[]=influencer&with[]=directorships&with[]=directorships.director&with[]=stats&with[]=stats.director`
    );
  }
  getCompanyNetworkChart(id_company: number): Observable<ChartNetwork> {
    return this.http.get<ChartNetwork>(
      `${this.baseURL}company/${id_company}/chart/network`
    );
  }
  getCompanyInfluenceChart(id_company: number): Observable<ChartInfluence> {
    return this.http.get<ChartInfluence>(
      `${this.baseURL}company/${id_company}/chart/influence`
    );
  }
  getCompanyPerformanceChart(id_company: number): Observable<ChartPerformance> {
    return this.http.get<ChartPerformance>(
      `${this.baseURL}company/${id_company}/chart/performance`
    );
  }
  getArticles(page = 1): Observable<PaginatedResponse<Article>> {
    return this.http.get<PaginatedResponse<Article>>(
      `${this.baseURL}articles?page=${page}&with[]=author&with[]=board&with[]=origin`
    );
  }
  getArticle(id: number): Observable<Article> {
    return this.http.get<Article>(
      `${this.baseURL}article/${id}?with[]=author&with[]=board&with[]=origin`
    );
  }
  getSubscriptionCheckoutPage(
    plan: string,
    billing_period: string
  ): Observable<{ checkout_url: string }> {
    return this.http.get<{ checkout_url: string }>(
      `${this.baseURL}subscription-checkout?plan=${plan}&billing_period=${billing_period}`
    );
  }

  getWatchlist(
    page = 1,
    search = '',
    limit = 10
  ): Observable<PaginatedResponse<Watchlist>> {
    let url = `${this.baseURL}watchlist?with[]=company&with[]=company.directorships&page=${page}&per_page=${limit}`;

    if (search && search.trim() !== '') {
      url += `&search=${search}`;
    }

    return this.http.get<PaginatedResponse<Watchlist>>(url);
  }

  getCompanyFromWatchlist(id_company: number): Observable<Watchlist> {
    return this.http.get<Watchlist>(
      `${this.baseURL}watchlist/company/${id_company}`
    );
  }

  addCompanyToWatchlist(id_company: number): Observable<Watchlist> {
    return this.http.post<Watchlist>(`${this.baseURL}watchlist`, {
      id_company: id_company,
    });
  }

  deleteWatchlist(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}watchlist/${id}`);
  }

  getPortfolio(
    page = 1,
    search = '',
    limit = 10
  ): Observable<PaginatedResponse<Portfolio>> {
    let url = `${this.baseURL}portfolio?with[]=company&with[]=company.directorships&page=${page}&per_page=${limit}`;

    if (search && search.trim() !== '') {
      url += `&search=${search}`;
    }

    return this.http.get<PaginatedResponse<Portfolio>>(url);
  }

  getCompanyFromPortfolio(id_company: number): Observable<Portfolio> {
    return this.http.get<Portfolio>(
      `${this.baseURL}portfolio/company/${id_company}`
    );
  }

  addCompanyToPortfolio(id_company: number): Observable<Portfolio> {
    return this.http.post<Portfolio>(`${this.baseURL}portfolio`, {
      id_company: id_company,
    });
  }

  deletePortfolio(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}portfolio/${id}`);
  }
}
