import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ArticleActions from './article.actions';
import { FfaService } from '../../services/ffa.service';

@Injectable()
export class ArticleEffects {
  loadArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticles),
      mergeMap(action =>
        this.api.getArticles(action.page).pipe(
          map(response => {
            return ArticleActions.loadArticlesSuccess({
              articles: response.data,
              currentPage: response.current_page,
              totalPages: response.last_page,
              totalItems: response.total,
            });
          }),
          catchError(error => of(ArticleActions.loadArticlesFailure(error)))
        )
      )
    )
  );

  loadArticleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticleById),
      mergeMap(action =>
        this.api.getArticle(action.id).pipe(
          map(response => {
            return ArticleActions.loadArticleByIdSuccess({
              article: response,
            });
          }),
          catchError(error => of(ArticleActions.loadArticleByIdFailure(error)))
        )
      )
    )
  );
  loadArticlesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticlesSuccess),
      map(ArticleActions.selectFeaturedArticle)
    )
  );

  constructor(private actions$: Actions, private api: FfaService) {}
}
