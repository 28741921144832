import { createAction, props } from '@ngrx/store';
import { Director } from '../../models';
export const loadDirectors = createAction(
  '[Director] Load Directors',
  props<{ page?: number; search?: string; limit?: number }>()
);

export const loadDirectorsSuccess = createAction(
  '[Director] Load Directors Success',
  props<{
    directors: Director[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadDirectorsFailure = createAction(
  '[Director] Load Directors Failure',
  props<{
    error: any;
  }>()
);

export const selectDirector = createAction(
  '[Director] Select Director',
  props<{ id: number }>()
);

export const loadDirectorById = createAction(
  '[Director] Load Director By Id',
  props<{ id: number }>()
);
export const loadDirectorByIdSuccess = createAction(
  '[Director] Load Director By Id Success',
  props<{ director: Director }>()
);
export const loadDirectorByIdFailure = createAction(
  '[Director] Load Director By Id Failure',
  props<{ error: any }>()
);

export const loadDirectorsById = createAction(
  '[Director] Load Directors By Id',
  props<{ ids: number[] }>()
);
export const loadDirectorsByIdSuccess = createAction(
  '[Director] Load Directors By Id Success',
  props<{
    directors: Director[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);
export const loadDirectorsByIdFailure = createAction(
  '[Director] Load Directors By Id Failure',
  props<{ error: any }>()
);

export const loadBiggestInfluencers = createAction(
  '[Directorship] Load BiggestInfluencers',
  props<{ page?: number; search?: string; limit?: number }>()
);

export const loadBiggestInfluencersSuccess = createAction(
  '[Directorship] Load BiggestInfluencers Success',
  props<{
    directors: Director[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  }>()
);

export const loadBiggestInfluencersFailure = createAction(
  '[Directorship] Load BiggestInfluencers Failure',
  props<{
    error: any;
  }>()
);
export const resetSelectedDirector = createAction(
  '[Director] Reset Selected Director'
);

export const resetSelectedDirectors = createAction(
  '[Director] Reset Selected Directors'
);
