import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CompanyActions from './company.actions';
import { Company } from '../../models';
import { ChartNetwork } from '../../models/chart-network';
import { ChartInfluence } from '../../models/chart-influence';
import { ChartPerformance } from '../../models/chart-performance';

type Nullable<T> = T | null;

export interface CompanyState extends EntityState<Company> {
  selectedCompany: Nullable<Company>;
  network_chart: Nullable<ChartNetwork>;
  performance_chart: Nullable<ChartPerformance>;
  influence_chart: Nullable<ChartInfluence>;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
  top3Companies: Nullable<Company[]>;
  top10Companies: Nullable<Company[]>;
  topCompanies: Nullable<Company[]>;
  topShortListCompanies: Nullable<Company[]>;
}

export const companyAdapter: EntityAdapter<Company> =
  createEntityAdapter<Company>({
    selectId: (company: Company) => company.id,
    sortComparer: false,
  });

export const initialState: CompanyState = companyAdapter.getInitialState({
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  loading: false,
  selectedCompany: null,
  network_chart: null,
  performance_chart: null,
  influence_chart: null,
  top3Companies: null,
  top10Companies: null,
  topCompanies: null,
  topShortListCompanies: null,
});

export const companiesReducer = createReducer(
  initialState,
  on(CompanyActions.loadCompanies, state => {
    return { ...state, loading: true };
  }),
  on(CompanyActions.loadTopCompanies, state => {
    return { ...state, loading: true };
  }),
  on(
    CompanyActions.loadCompaniesSuccess,
    (state, { companies, currentPage, totalPages, totalItems }) =>
      companyAdapter.setAll([...companies], {
        ...state,
        currentPage,
        totalPages,
        totalItems,
        loading: false,
      })
  ),
  on(CompanyActions.loadTopCompaniesSuccess, (state, { companies }) => ({
    ...state,
    topCompanies: companies,
    top3Companies: companies.slice(0, 3),
    top10Companies: companies.slice(0, 10),
    topShortListCompanies: companies.slice(3),
    loading: false,
    error: null,
  })),
  on(CompanyActions.selectCompany, (state, { id }) => {
    return { ...state, selectedCompanyId: id };
  }),
  on(CompanyActions.loadCompanyById, state => ({
    ...state,
    selectedCompany: null,
    loading: true,
  })),
  on(CompanyActions.loadNetworkChart, state => ({ ...state, loading: true })),
  on(CompanyActions.loadPerformanceChart, state => ({
    ...state,
    loading: true,
  })),
  on(CompanyActions.loadInfluenceChart, state => ({ ...state, loading: true })),
  on(CompanyActions.loadCompanyByIdSuccess, (state, { company }) => ({
    ...state,
    selectedCompany: company,
    loading: false,
    error: null,
  })),
  on(CompanyActions.loadCompanyByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CompanyActions.loadNetworkChartSuccess, (state, { chart }) => ({
    ...state,
    network_chart: chart,
    loading: false,
    error: null,
  })),
  on(CompanyActions.loadPerformanceChartSuccess, (state, { chart }) => ({
    ...state,
    performance_chart: chart,
    loading: false,
    error: null,
  })),
  on(CompanyActions.loadInfluenceChartSuccess, (state, { chart }) => ({
    ...state,
    influence_chart: chart,
    loading: false,
    error: null,
  })),
  on(CompanyActions.loadInfluenceChartFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CompanyActions.loadPerformanceChartFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CompanyActions.loadNetworkChartFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(CompanyActions.resetSelectedCompany, state => ({
    ...state,
    selectedCompany: null,
  }))
);

export function reducer(state: CompanyState | undefined, action: Action) {
  return companiesReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  companyAdapter.getSelectors();

// select the array of user ids
export const selectCompanyIds = selectIds;

// select the dictionary of user entities
export const selectCompanyEntities = selectEntities;

// select the array of users
export const selectAllCompanies = selectAll;

// select the total user count
export const selectCompanyTotal = selectTotal;
