import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private readonly http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {
      email,
      password,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/register`, user);
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/reset-password`, {
      email,
      token,
      password,
      password_confirmation,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me?with[]=subscription`, {
      headers: httpHeaders,
    });
  }

  refreshToken(token: string) {
    return this.http.post<UserModel>(`${API_USERS_URL}/refresh`, { token });
  }
}
